import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class FullTestimonials extends React.Component {
  renderArrows = () => {
    return (
      <div className="arrows">
        <div className="container">
          <div
            onClick={() => this.slider.slickNext()}
            className="next cursor-pointer"
          >
            <span className="pe-7s-angle-right"></span>
          </div>
          <div
            onClick={() => this.slider.slickPrev()}
            className="prev cursor-pointer"
          >
            <span className="pe-7s-angle-left"></span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <section
        className={`testimonials ${this.props.withIMG
          ? "section-padding bg-img"
          : this.props.withCOLOR
            ? "section-padding back-color"
            : this.props.noPadding ? ""
              : "section-padding"
          } ${this.props.classText ? this.props.classText : ""}`}
        style={{
          backgroundImage: `${this.props.withIMG ? "url(/img/testim-img.jpg)" : "none"
            }`,
        }}
      >

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="sec-head  text-center">
                <h6 className="wow fadeIn" data-wow-delay=".5s">
                  Capabilities
                </h6>
                <h3 className="wow color-font ">
                  Why choose us
                </h3>
              </div>
            </div>
          </div>
        </div>


        <div className="container-fluid position-re">
          <div className="row wow fadeInUp" data-wow-delay=".5s">
            <div className="col-lg-12">
              <Slider
                className="slic-item"
                {...{
                  ref: (c) => (this.slider = c),
                  speed: 1000,
                  dots: false,
                  infinite: true,
                  arrows: true,
                  centerMode: true,
                  autoplay: true,
                  rows: 1,
                  slidesToScroll: 1,
                  slidesToShow: 3,
                  responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 1,
                        centerMode: false,
                      },
                    },
                    {
                      breakpoint: 767,
                      settings: {
                        slidesToShow: 1,
                        centerMode: false,
                      },
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        centerMode: false,
                      },
                    },
                  ],
                }}
              >
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author custom-auther">
                        <div class="item-box ">
                          <span class="icon pe-7s-vector"></span>
                        </div>
                        <span className="author-details fw-500 text-center">
                          EASY AND USERFRIENDLY
                        </span>
                      </div>
                    </div>
                  </div>
                  <p className="testimonial-text">
                    Adzenic’s interface is specifically designed to save your time when it is about creating the best and captivating rich media ads. It offers an extremely easy-to-use interface thus no prior design experience is required to create crisp and professional-looking ads.
                  </p>
                </div>
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author custom-auther">
                        <div class="item-box ">
                          <span class="icon pe-7s-rocket"></span>
                        </div>

                        <span className="author-details  fw-500 text-center">
                          Fast And Responsive
                        </span>
                      </div>
                    </div>
                  </div>
                  <p>
                    The tools and options of Adzenic helps in creating custom rich-media ads faster. With different designing elements, one tap animation option and quick export feature helps you get the desirable output in minutes.
                  </p>
                </div>
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author custom-auther">
                        <div class="item-box ">
                          <span class="icon pe-7s-browser"></span>
                        </div>

                        <span className="author-details fw-500 text-center">
                          No Coding Needed
                        </span>
                      </div>
                    </div>
                  </div>
                  <p>
                    Adzenic makes it possible to create Rich Media HTML ads without coding. All you need to do is design and animate your banner with easy drag and drop features and Adzenic will do all the coding at the backend and provide clean and optimized output.
                  </p>
                </div>
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author custom-auther">
                      <div class="item-box ">
                          <span class="icon pe-7s-browser"></span>
                        </div>
                        <span className="author-details  fw-500 text-center">
                          Always Evolving
                        </span>
                      </div>
                    </div>
                  </div>
                  <p>
                    Adzenic team is always on their toes to bring out new features to the platform which can help designing process further easier. It is our futuristic vision and commitment to solve repetitive and complex designing process, specially for the advertising industry.
                  </p>
                </div>

              </Slider>
            </div>
          </div>
          {this.renderArrows()}
        </div>
      </section>
    );
  }
}

export default FullTestimonials;
