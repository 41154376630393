import React from 'react'
import funFactDate from 'data/sections/fun-fact.json'
import Split from "../Split";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";


const NumbersProducts = () => {
  React.useEffect(() => {
    console.clear();
  })
  return (
    <section className="number-sec section-padding number-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-10">
            <div className="sec-head  text-center number-sec-head">

              <h3 className="fw-600  ls1 mb-30 color-font">
                Vision
              </h3>
              <p className="wow fadeIn" data-wow-delay=".5s">
                Our ultimate goal is to bring the best of ad creativity with ultimate ease without any professional designing experience in no time.
                We want to make the designing process, the least complicated and less time-consuming part in the entire online advertisment's life cycle.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="row">
            {funFactDate.map((item) => (
              <div key={item.id} className="col-md-4">
                <div className="item no-bord sm-mb50">
                  <span className={`icon ${item.icon}`}></span>
                  <h3 className="">
                    &nbsp;
                    <CountUp redraw={true} end={item.value} duration="3">
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="count" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </h3>
                  <Split>
                    <p className="wow txt words chars splitting" data-splitting>
                      {item.content}
                    </p>
                  </Split>
                </div>
              </div>
            ))}
          </div> */}
      </div>
    </section>
  );
}

export default NumbersProducts