import React from "react";
import { Link } from "gatsby";
import AboutUs1Date from "../../data/sections/about-us1-product.json";

const AboutUsProduct = () => {
  return (
    <section className="about-us pt-100">
      <div className="container">
        <div className="row">

          <div className="col-lg-6 img img-product  md-mb50">
            <div class="mb-50 " style={{padding:10}}>
              <img src={AboutUs1Date.image} alt="" />
            </div>

          </div>
          <div className="col-lg-6 valign md-mb50">
            <div className="mb-50">
              <h6 className="fw-200  ls10 mb-10">
                {AboutUs1Date.smallTitle}
              </h6>
              <h3 className="fw-600  ls1 mb-30 color-font">
                {AboutUs1Date.title}
              </h3>
              <p className="mb-50">{AboutUs1Date.content}</p>
              <p>{AboutUs1Date.content2}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsProduct;
